.cardList {
    .mediaContent {
        border-radius: 12px;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 8px;
        overflow: auto;
        touch-action: auto;
    }

    .top {
        height: 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        margin-bottom: 8px;

        .title {
            color: #d4d4d4;
            font-size: 18px;
            font-weight: 700;
        }

        .more {
            color: #d4d4d4;
            font-size: 14px;
            font-weight: 700;
            padding-bottom: 2px;
            border-bottom: 1px solid #d4d4d4;
        }
    }
}